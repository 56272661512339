import { Avatar, Box, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { Tab, useRecordContext } from "react-admin";
import { grants } from "../backoffice.access_control";
import { OpenInFirestoreButton } from "../buttons/OpenInFirestoreButton";
import { OpenInHubspotButton } from "../buttons/OpenInHubspotButton";
import { ActiveStudentsField } from "../fields/ActiveStudentsField";
import { InstructorAppointmentsList } from "./instructor/InstructorAppointmentsList";
import { InstructorEventsCalendar } from "../misc/calendar/InstructorEventsCalendar";
import { Instructor } from "../providers/instructorsProvider";
import { autovioColors } from "../misc/backofficeTheme";
import { ShowBase } from "../misc/ShowBase";
import { InstructorStudentsListAndInstructorMap } from "./instructor/InstructorStudentsListAndInstructorMap";
import { InstructorAdminForm } from "./instructor/InstructorAdminForm";
import { Tabs } from "../misc/Tabs";
import { Row } from "../misc/Row";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { InstructorInAppNotificationsList } from "./instructor/InstructorInAppNotificationsList";
import { useEffect, useState } from "react";
import { useAutovioContext } from "../hooks/useAutovioContext";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NameHeader } from "../misc/NameHeader";

export function InstructorDetails() {
  return (
    <ShowBase>
      <InstructorDetailsTabs />
      {/** Add some space at the bottom of the page ... */}
      <Box height="20px" />
    </ShowBase>
  );
}

function InstructorButtons() {
  const instructor = useRecordContext<Instructor>();
  const { greyUltraLight, green } = autovioColors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleEmailClick = () => {
    const url = `https://app.hubspot.com/contacts/19951845/record/0-1/${instructor.hubspotContactId}/?interaction=email`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <Row sx={{ display: "block" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handleEmailClick}>
          <AlternateEmailIcon sx={{ fontSize: 20 }} />
        </IconButton>
        {grants.includes("admin") && (
          <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handleClick}>
            <MoreVertIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ width: 220 }}>
        {grants.includes("viewHubspot") && (
          <MenuItem onClick={handleClose}>
            <OpenInHubspotButton showTitle />
          </MenuItem>
        )}

        {grants.includes("viewFirestore") && (
          <MenuItem onClick={handleClose}>
            <OpenInFirestoreButton showTitle />
          </MenuItem>
        )}
      </Menu>
    </Row>
  );
}

function InstructorDetailsHeader({ instructor }: { instructor: Instructor }) {
  return (
    <Row sx={{ my: "20px" }} spacing="50px" alignItems="center">
      <Row spacing="20px" alignItems="center">
        <Avatar src={instructor.avatarOverrideUrl ?? instructor.avatarUrl} sx={{ width: "60px", height: "60px" }} />
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <NameHeader>{instructor.name}</NameHeader>
          <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>
            {instructor.isDrivingSchoolManager ? "Fahrschulleiter" : "Fahrlehrer"}
          </Typography>
        </Box>
      </Row>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          aktive Fahrschüler
        </Typography>
        <ActiveStudentsField />
      </Stack>
    </Row>
  );
}

function InstructorDetailsTabs() {
  const instructor = useRecordContext<Instructor>();
  const [autovioContext, setAutovioContext] = useAutovioContext();
  useEffect(() => {
    if (instructor && instructor.drivingSchoolId !== autovioContext.drivingSchoolId) {
      setAutovioContext({ drivingSchoolId: instructor.drivingSchoolId });
    }
  }, [autovioContext.drivingSchoolId, instructor?.id]);

  if (!instructor) {
    return <LoadingIndicator />;
  }

  return (
    <Tabs
      sx={{ borderRadius: "12px", border: "1px solid #e5e5e5", padding: "5px 30px 10px 30px" }}
      header={<InstructorDetailsHeader instructor={instructor} />}
      additionalButtons={<InstructorButtons />}
      separateCards
    >
      <Tab label="Fahrschüler" /* no path attribute here, so the tab is shown by default */>
        <InstructorStudentsListAndInstructorMap />
      </Tab>
      <Tab label="Kalender" path="calendar">
        <InstructorEventsCalendar />
      </Tab>
      <Tab label="Terminliste" path="appointments">
        <InstructorAppointmentsList />
      </Tab>
      {grants.includes("viewInAppNotifications") && (
        <Tab label="In-App-Benachrichtigungen" path="in-app-notifications">
          <InstructorInAppNotificationsList />
        </Tab>
      )}
      {grants.includes("admin") && (
        <Tab label="Admin" path="admin">
          <InstructorAdminForm />
        </Tab>
      )}
    </Tabs>
  );
}
