import { DateTime } from "luxon";
import { RaRecord } from "react-admin";
import { LessonExtension } from "./LessonExtension";
import { OnlineMeeting } from "./OnlineMeeting";
import { PostalAddress } from "./PostalAddress";
import { t } from "./types";
import { type z } from "zod";

export interface BaseAutovioCalendarEvent extends RaRecord {
  id: string;
  start: DateTime;
  end: DateTime;
  drivingSchoolId: string;
  bufferTimes?: BufferTimes;
  resources?: string[];
  deletionReason?: string;
  editReason?: string;
  editCount: number;
  editWithReasonCount: number;
  createdAt: DateTime;
  createdBy: string;
  updatedAt?: DateTime;
  updatedBy?: string;
  deleted: boolean;
  deletedAt?: DateTime;
  deletedBy?: string;
}

interface BufferTimes {
  readonly preparationTime: number; // in Minutes
  readonly wrapUpTime: number; // in Minutes
}

export interface TheoryLessonStudentAttendance {
  agreedCancellationPolicy: any;
  attended?: boolean;
  bookedAt?: DateTime;
  noShow?: boolean;
  cancelReason?: string;
  rsvp: "accepted" | "rejected";
}

export const RsvpEnum = t.enum(["pending", "accepted", "rejected", "declined", "canceled", "no-show"]);

export type Rsvp = z.infer<typeof RsvpEnum>;

export interface PaidLessonStudentAttendance {
  agreedCancellationPolicy: any;
  bookedAt?: DateTime;
  bookedTrainingId?: string;
  canceledAt?: DateTime;
  cancelReason?: string;
  declinedAt?: DateTime;
  rsvp: Rsvp;
  uid: string;
}

export interface TheoryLesson extends BaseAutovioCalendarEvent {
  type: "TheoryLesson";
  courseUid?: string;
  theoryUnit: number;
  instructorId: string;
  students: { [uid: string]: TheoryLessonStudentAttendance };
  numBookedStudents: number;
  maxStudents: number;
  location: ({ type: "OnlineMeeting" } & OnlineMeeting) | ({ type: "PostalAddress" } & PostalAddress);
}

export interface TheoryExam extends BaseAutovioCalendarEvent {
  type: "TheoryExam";
  student: PaidLessonStudentAttendance;
  startLocation: PostalAddress;
  status?:
    | "invited"
    | "booked"
    | "declinedByStudent"
    | "canceledByStudent"
    | "canceledByInstructor"
    | "passed"
    | "failed"
    | "no-show";
  theoryExamResult?: "passed" | "failed";
}

export interface DrivingLesson extends BaseAutovioCalendarEvent {
  type: "DrivingLesson";
  drivingLessonType:
    | "normal"
    | "ueberlandfahrt"
    | "autobahnfahrt"
    | "nachtfahrt"
    | "praktischePruefung"
    | "schaltkompetenz"
    | "praktischeUnterweisung"
    | "fahrprobe";
  extensions: LessonExtension[];
  instructorId: string;
  student: PaidLessonStudentAttendance;
  startedAt?: DateTime;
  endedAt?: DateTime;
  startLocation: PostalAddress;
  isBooked: boolean;
  status?:
    | "invited"
    | "booked"
    | "declinedByStudent"
    | "canceledByStudent"
    | "canceledByInstructor"
    | "finished"
    | "passed" // <-- for practical exam
    | "failed" // <-- for practical exam
    | "no-show";
}

export type PracticalExam = DrivingLesson & {
  drivingLessonType: "praktischePruefung";
  practicalExamResult?: "passed" | "failed";
};

export interface RemoteLesson extends BaseAutovioCalendarEvent {
  type: "RemoteLesson";
  extensions: LessonExtension[];
  instructorId: string;
  student: PaidLessonStudentAttendance;
}

export type PaidLesson = DrivingLesson | RemoteLesson;

export interface ASFCourseSessionAttendance {
  attended?: boolean;
  bookedAt?: DateTime;
  noShow?: boolean;
  rsvp?: never;
}

export interface ASFCourseSession extends BaseAutovioCalendarEvent {
  type: "ASFCourseSession";
  sessionType: "sitzung1" | "fahrprobe" | "sitzung2" | "sitzung3" | "sitzung4";
  courseUid?: string;
  instructorId: string;
  numBookedParticipants: number;
  participants: { [uid: string]: ASFCourseSessionAttendance };
  location: PostalAddress & { type?: "PostalAddress" };
}

export type OtherEventCategory =
  | "businessTrip"
  | "cancelledDrivingLesson"
  | "carCare"
  | "changeCars"
  | "chargeCars"
  | "examPlanning"
  | "journeyFromAndToCustomer"
  | "meeting"
  | "office"
  | "other"
  | "preparationTheory"
  | "private"
  | "publicHoliday"
  | "sickness"
  | "training"
  | "vacation";

export interface OtherEvent extends BaseAutovioCalendarEvent {
  type: "Other";
  instructorId: string;
  category: OtherEventCategory;
  notes?: string;
}

interface UnknownEvent extends BaseAutovioCalendarEvent {
  type: "Unknown";
}

export type AutovioCalendarEvent =
  | TheoryLesson
  | TheoryExam
  | DrivingLesson
  | RemoteLesson
  | ASFCourseSession
  | OtherEvent
  | UnknownEvent;

export const isTheoryLesson = (event: AutovioCalendarEvent): event is TheoryLesson => event.type === "TheoryLesson";
export const isTheoryExam = (event: AutovioCalendarEvent): event is TheoryExam => event.type === "TheoryExam";
export const isDrivingLesson = (event: AutovioCalendarEvent): event is DrivingLesson => event.type === "DrivingLesson";
export function isPracticalExam(event: AutovioCalendarEvent): event is PracticalExam {
  return event.type === "DrivingLesson" && event.drivingLessonType === "praktischePruefung";
}
export const isRemoteLesson = (event: AutovioCalendarEvent): event is RemoteLesson => event.type === "RemoteLesson";
export const isPaidLesson = (event: AutovioCalendarEvent): event is PaidLesson =>
  isDrivingLesson(event) || isRemoteLesson(event);
export const isASFCourseSession = (event: AutovioCalendarEvent): event is ASFCourseSession =>
  event.type === "ASFCourseSession";
export const isOtherEvent = (event: AutovioCalendarEvent): event is OtherEvent => event.type === "Other";
