import { Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, Link } from "@mui/material";
import { useState } from "react";
import {
  CheckboxGroupInput,
  EditBase,
  Form,
  SaveButton,
  useEditContext,
  useGetList,
  useNotify,
  useRecordContext,
  useRefresh,
} from "react-admin";
import { reportError } from "../backoffice.utils";
import { useDialog } from "../hooks/useDialog";
import { Instructor } from "../providers/instructorsProvider";
import { Student, studentsProvider } from "../providers/studentsProvider";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useAutovioContext } from "../hooks/useAutovioContext";

export function ChangeInstructorsLink({ label }: { label: string }) {
  const student = useRecordContext<Student>();
  const { dialogProps, openDialog } = useDialog();
  return (
    <>
      <Link component="button" variant="caption" underline="none" onClick={openDialog}>
        {label}
      </Link>
      <ChangeInstructorsDialog student={student} {...dialogProps} />
    </>
  );
}

interface ChangeInstructorsDialogProps {
  open: boolean;
  student?: Student;
  onClose: () => void;
}

function ChangeInstructorsDialog({ open, student, onClose }: ChangeInstructorsDialogProps) {
  const { refetch } = useEditContext();
  const [loading, setLoading] = useState(false);
  const [{ drivingSchoolId }] = useAutovioContext();
  const { data: instructors } = useGetList<Instructor>(
    "instructors",
    {
      filter: { drivingSchoolId },
      pagination: { page: 1, perPage: 1000 },
    },
    { enabled: !!drivingSchoolId },
  );
  const notify = useNotify();
  const refresh = useRefresh();

  const assignInstructors = async (instructorIds: string[]) => {
    setLoading(true);
    try {
      if (!student) {
        throw new Error("Invalid state: !student");
      }
      await studentsProvider.assignInstructors(student.id, instructorIds);
      notify(`Fahrlehrer von ${student.name} erfolgreich geändert.`, { type: "success" });
      if (refetch) {
        await refetch();
      } else {
        refresh();
      }
    } catch (error) {
      reportError(`Failed to change instructors of student ${student?.id}`, error);
      const errorMessage =
        instructorIds.length === 1
          ? student
            ? `Fehler beim Ändern des Fahrlehrers von ${student.name}.`
            : `Fehler beim Ändern des Fahrlehrers.`
          : student
          ? `Fehler beim Ändern der Fahrlehrer von ${student.name}.`
          : `Fehler beim Ändern der Fahrlehrer.`;
      notify(errorMessage, { type: "error" });
    } finally {
      setLoading(false);
      onClose();
    }
  };

  const validateSelection = (data: { [field: string]: any }) => {
    const { instructorIds } = data;
    const errors: { [field: string]: any } = {};
    if (instructorIds.length === 0) {
      errors.instructorIds = "Bitte mindestens einen Fahrleher auswählen.";
    }
    return errors;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {!loading && `Fahrlehrer von ${student?.name ?? "..."} ändern`}
        {loading && `Ändere Fahrlehrer von ${student?.name} ...`}
      </DialogTitle>
      <DialogCloseButton onClose={onClose} />
      <EditBase>
        <Form
          onSubmit={(data) => assignInstructors(data.instructorIds)}
          validate={validateSelection}
          warnWhenUnsavedChanges
        >
          <DialogContent>
            {(loading || !instructors?.length) && <LinearProgress />}
            {!loading && instructors?.length && (
              <CheckboxGroupInput
                source="instructorIds"
                row={false}
                choices={instructors
                  .map((instructor) => ({
                    id: instructor.id,
                    name: instructor.name,
                  }))
                  .sort((a, b) => a.name.localeCompare(b.name))}
              />
            )}
          </DialogContent>
          <DialogActions>
            <SaveButton />
          </DialogActions>
        </Form>
      </EditBase>
    </Dialog>
  );
}
