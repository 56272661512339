import { Box } from "@mui/material";
import { autovioColors } from "./backofficeTheme";

function IconInACircle({
  icon,
  style,
}: {
  icon: JSX.Element;
  style?: { backgroundColor: string; size: number | string };
}) {
  const { greyUltraLight } = autovioColors;
  return (
    <Box
      sx={{
        background: style?.backgroundColor || greyUltraLight,
        color: "#9b9b9b",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: style?.size ?? 40,
        width: style?.size ?? 40,
        borderRadius: "50%",
      }}
    >
      {icon}
    </Box>
  );
}

export default IconInACircle;
