import type { User } from "@firebase/auth";
import { reportError } from "./backoffice.utils";
import { auth, firestore } from "./firebase";
import { AuthProvider } from "react-admin";
import { FirebaseAuthProvider } from "./providers/firebaseAuthProvider";
import * as Sentry from "@sentry/react";
import intersection from "lodash/intersection";
import { CustomizationFlag, CustomizationFlagEnum } from "./model/customizationFlags";
import { drivingSchoolsProvider } from "./providers/drivingSchoolsProvider";
import { doc, getDoc } from "firebase/firestore";
import { getRedirectResult } from "firebase/auth";

export let authProvider: AuthProvider;

export let currentUser: User | null | undefined;
/** All grants of the current user. */
export let grants: ReadonlyArray<Grant>;
/** Might be set if the current user is staff or a driving school manager. */
export let restrictAccessToDrivingSchoolIds: Array<string> | undefined;

interface AuthState {
  currentUser: typeof currentUser;
  grants: typeof grants;
  restrictAccessToDrivingSchoolIds: typeof restrictAccessToDrivingSchoolIds;
}

export async function initAccessControl(): Promise<{ currentUser: typeof currentUser; grants: ReadonlyArray<Grant> }> {
  authProvider = new FirebaseAuthProvider(auth);
  try {
    const result = await getRedirectResult(auth);
    if (result) {
      console.info(`getRedirectResult(auth) => ${result.operationType} as user ${result.user.uid}`);
    }
  } catch (error) {
    console.info("Failed to get redirect result", error);
  }
  return new Promise<{ currentUser: typeof currentUser; grants: ReadonlyArray<Grant> }>((resolveCurrentUser) =>
    auth.onIdTokenChanged(async (user) => {
      // delay the execution here, to prevent the "Could not reach Cloud Firestore backend." error ...
      const newAuthState = await new Promise<AuthState>((resolveAuthState) =>
        setTimeout(async () => {
          const currentUser: AuthState["currentUser"] = user;
          let grants: AuthState["grants"] = [];
          let restrictAccessToDrivingSchoolIds: AuthState["restrictAccessToDrivingSchoolIds"] = undefined;
          try {
            if (user) {
              const {
                claims: { roles, entitledForDrivingSchools, instructorDrivingSchoolUid },
              } = await user.getIdTokenResult();
              if (Array.isArray(roles)) {
                if (roles.includes("admin")) {
                  // No access restrictions for admins.
                } else if (isStaff(roles)) {
                  if (Array.isArray(entitledForDrivingSchools) && entitledForDrivingSchools.length > 0) {
                    restrictAccessToDrivingSchoolIds = entitledForDrivingSchools;
                  }
                } else if (roles.includes("drivingSchoolManager")) {
                  if (instructorDrivingSchoolUid) {
                    restrictAccessToDrivingSchoolIds = [instructorDrivingSchoolUid as string];
                  } else {
                    throw new Error(`instructorDrivingSchoolUid is missing in claims of user ${user.uid}`);
                  }
                }
                let customizationFlags: Array<CustomizationFlag> = CustomizationFlagEnum.options;
                if (restrictAccessToDrivingSchoolIds?.length === 1) {
                  const { data: drivingSchool } = await drivingSchoolsProvider.getOne("drivingSchools", {
                    id: restrictAccessToDrivingSchoolIds[0],
                  });
                  customizationFlags = drivingSchool.customizations;
                }
                grants = grantsForRolesAndCustomizationFlags(roles, customizationFlags);
                const { grants: individualGrants } = (await getDoc(doc(firestore, `/users/${user.uid}`))).data() ?? {};
                if (Array.isArray(individualGrants)) {
                  for (let grant of individualGrants) {
                    if (typeof grant !== "string") {
                      continue;
                    }
                    if (grant.startsWith("!")) {
                      grant = grant.substring(1);
                      if (grants.includes(grant)) {
                        grants = grants.filter((it) => it !== grant);
                      }
                    } else {
                      if (!grants.includes(grant as Grant)) {
                        grants = [...grants, grant as Grant];
                      }
                    }
                  }
                }
                if (currentUser && isDeveloper(currentUser)) {
                  grants = [
                    ...grants,
                    "copyIdTokenToClipboard",
                    "createDataSnapshotForVisualTest",
                    "viewPerformanceOverviewLegacyData",
                  ];
                }
              }
            }
          } catch (error) {
            reportError("Failed to initialize grants", error);
          } finally {
            resolveAuthState({ currentUser, grants, restrictAccessToDrivingSchoolIds });
          }
          if (user) {
            Sentry.setUser({
              id: user.uid,
              email: user.email || user.providerData[0].email || "???",
              username: user.displayName || "???",
            });
          }
        }, 250),
      );
      ({ currentUser, grants, restrictAccessToDrivingSchoolIds } = newAuthState);
      resolveCurrentUser({ currentUser, grants });
    }),
  );
}

export async function setUpVisualTest(): Promise<void> {
  const { DummyAuthProvider, teoTester } = await import("./backoffice.test_utils");
  authProvider = new DummyAuthProvider(teoTester);
  currentUser = teoTester;
  grants = Object.keys(adminGrants) as Array<Grant>;
  restrictAccessToDrivingSchoolIds = undefined;
  localStorage.clear();
}

function isStaff(roles: Array<string>): boolean {
  return intersection(roles, ["admin", "dispatcher", "partnerSuccess", "studentSales"]).length > 0;
}

function isDeveloper(user?: User | null): boolean {
  if (!user) {
    return false;
  }
  const googleData = user.providerData.find((it) => it.providerId === "google.com");
  const email = googleData?.email ?? user.email ?? "";
  return ["amir@autovio.de", "michael@autovio.de", "til@autovio.de", "tom.wallroth@autovio.de"].includes(email);
}

const studentSalesGrants: ReadonlyArray<Grant> = [
  "addExamInThePast",
  "addStudentToTheoryLesson",
  "bookAppointments",
  "changeBundle",
  "createFundingInstructions",
  "editStudentPaymentStrategy",
  "editStudentPersonalData",
  "giftCredits",
  "giftVouchers",
  "refundPayments",
  "removeStudentFromTheoryLesson",
  "resetPassword",
  "resetTheoryLearning",
  "retryPayments",
  "startOrganizingTheoryExam",
  "viewAppliedReferralCodes",
  "viewAuthProviders",
  "viewBackendAccounts",
  "viewBundles",
  "viewDrivingSchoolBusinessIntelligenceData",
  "viewHubspot",
  "viewNonFullyActivatedStudents",
  "viewPerformanceOverview",
  "viewPromoCodes",
  "viewStudentBalance",
  "viewStudentCredits",
  "viewStudentPayments",
  "updateInvoicePDF",
];

const partnerSuccessGrants: ReadonlyArray<Grant> = [
  ...studentSalesGrants,
  "viewAutovioBusinessIntelligenceData",
  "viewDeletedVehicles",
  "viewFinanceReports",
  "viewPayoutInformation",
  "viewPerformanceOverviewTakeRate",
  "viewPerformanceOverviewCompensation",
  "updateInvoicePDF",
];

const dispatcherGrants: ReadonlyArray<Grant> = [
  "addExamInThePast",
  "addStudentToTheoryLesson",
  "bookAppointments",
  "changeBundle",
  "editStudentPersonalData",
  "giftCredits",
  "removeStudentFromTheoryLesson",
  "resetPassword",
  "resetTheoryLearning",
  "startOrganizingTheoryExam",
  "viewAuthProviders",
  "viewInvoiceCustomers",
  "viewStudentBalance",
  "viewStudentCredits",
  "viewStudentPayments",
];

const drivingSchoolAnalystGrants: ReadonlyArray<Grant> = ["viewDrivingSchoolBusinessIntelligenceData"];

const adminGrants: { [k in Grant]: boolean } = {
  // a small hack to make typescript remind us when we forget to add a grant to the admin role.
  // there is AFAIK no way to make typescript force an array to have all values of an enum, but we can at least
  // create an object that has all values of the enum as keys and then use that object to construct the admin grants array.
  activateStudentWithOutstandingPayments: true,
  addExamInThePast: true,
  addStudentToTheoryLesson: true,
  admin: true,
  blockStudent: true,
  bookAppointments: true,
  changeBundle: true,
  changePrice: true,
  createFundingInstructions: true,
  copyIdTokenToClipboard: false,
  createDataSnapshotForVisualTest: false,
  editBundles: true,
  editStudentApplicationFeePercentage: true,
  editStudentPaymentStrategy: true,
  editStudentBudget: true,
  editStudentPersonalData: true,
  giftCredits: true,
  giftVouchers: true,
  hubspotSync: true,
  overrideFeatureToggles: true,
  refundPayments: true,
  removeStudentFromTheoryLesson: true,
  resetPassword: true,
  resetTheoryLearning: true,
  retryPayments: true,
  retryPaymentsUsingPaymentMethod: true,
  updateInvoicePDF: true,
  sendDunningNotice: true,
  startOrganizingTheoryExam: true,
  viewADAS: true,
  viewAdditionalEventDetails: true,
  viewAreas: true,
  viewAppliedReferralCodes: true,
  viewAuthProviders: true,
  viewAutovioBusinessIntelligenceData: true,
  viewAutovioEmployees: true,
  viewB2bInvoices: true,
  viewBackendAccounts: true,
  viewBundles: true,
  viewCustomizationFlagsUsage: true,
  viewDeletedVehicles: true,
  viewDrivingSchoolBusinessIntelligenceData: true,
  viewDunning: true,
  viewHubspot: true,
  viewFinanceReports: true,
  viewFirestore: true,
  viewInAppNotifications: true,
  viewInvoiceCustomers: true,
  viewNonFullyActivatedStudents: true,
  viewPayoutInformation: true,
  viewPerformanceOverview: true,
  viewPerformanceOverviewCompensation: true,
  viewPerformanceOverviewTakeRate: true,
  viewPerformanceOverviewColumnSelection: true,
  viewPerformanceOverviewDownloadReport: true,
  viewPerformanceOverviewLegacyData: false,
  viewProducts: true,
  viewPromoCodes: true,
  viewStripe: true,
  viewStudentBalance: true,
  viewStudentCredits: true,
  viewStudentPayments: true,
  viewTrainings: true,
  viewUsersToBeDeleted: true,
  webinar: false,
};

function grantsForRolesAndCustomizationFlags(
  roles: Array<string>,
  customizationFlags: Array<CustomizationFlag>,
): ReadonlyArray<Grant> {
  if (roles.includes("admin")) {
    return Object.entries(adminGrants)
      .filter(([_, v]) => v)
      .map(([k, _]) => k) as Grant[];
  }
  let grants: Grant[] = [];
  if (roles.includes("partnerSuccess")) {
    grants = [...grants, ...partnerSuccessGrants];
  }
  if (roles.includes("studentSales")) {
    grants = [...grants, ...studentSalesGrants];
  }
  if (roles.includes("dispatcher")) {
    grants = [...grants, ...dispatcherGrants];
  }
  if (roles.includes("drivingSchoolManager")) {
    grants = [
      ...grants,
      "addStudentToTheoryLesson",
      "changePrice",
      "editStudentPersonalData",
      "removeStudentFromTheoryLesson",
      ...(customizationFlags.includes("theoryLearning") ? (["resetTheoryLearning"] satisfies Array<Grant>) : []),
      "startOrganizingTheoryExam",
      "viewAreas",
      "viewBundles",
      "viewDrivingSchoolBusinessIntelligenceData",
      ...(customizationFlags.includes("paymentAndInvoicing")
        ? ([
            "viewB2bInvoices",
            "viewFinanceReports",
            "viewPerformanceOverview",
            "viewPerformanceOverviewDownloadReport",
            "viewPayoutInformation",
          ] satisfies Array<Grant>)
        : []),
    ];
  }
  if (roles.includes("drivingSchoolAnalyst")) {
    grants = [...grants, ...drivingSchoolAnalystGrants];
  }
  return [...new Set(grants)];
}
