import { Instructor } from "../providers/instructorsProvider";
import { Student } from "../providers/studentsProvider";
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { type ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { StudentSelect } from "../inputs/StudentSelect";
import { LoadingButton } from "@mui/lab";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import {
  Identifier,
  LinearProgress,
  Tab,
  useGetList,
  useGetManyReference,
  useGetOne,
  useNotify,
  useRefresh,
} from "react-admin";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";
import { DrivingLicenseClass, isForMotorcycle, isForTrailer } from "../model/DrivingLicenseClass";
import { ExamType } from "../model/ExamType";
import { Controller, FormProvider, useForm, useFormContext, useWatch, Validate } from "react-hook-form";
import { DateTime, Interval } from "luxon";
import { Car, Motorcycle, Trailer } from "../providers/resourceProviders";
import { ExamLocation } from "../model/ExamLocation";
import { useQueryClient } from "react-query";
import {
  bookDrivingLesson,
  bookExam,
  getPriceSuggestion,
  GetPriceSuggestionRequestSchema,
} from "../api/backoffice.api";
import { examLocationsProvider } from "../providers/examLocationsProvider";
import { Money } from "../model/Money";
import { moneyFormatter } from "../misc/Money";
import { grants } from "../backoffice.access_control";
import { renderAddress } from "../misc/AddressDisplay";
import { handleBookingError } from "../utils/handleBookingError";
import { Tabs } from "../misc/Tabs";
import { StartingPoint, startingPointsProvider } from "../providers/startingPointsProvider";
import { DrivingLesson } from "../model/autovioCalendarEvents";
import { instructorRecommendationSettingsProvider } from "../providers/instructorRecommendationSettingsProvider";
import { Row } from "../misc/Row";
import { type DateClickArg } from "@fullcalendar/interaction";
import { DialogCloseButton } from "../misc/DialogCloseButton";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { autovioColors } from "../misc/backofficeTheme";
import { Column } from "../misc/Column";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { formatTime } from "../utils/calendar";

const OWN_MOTORCYCLE_PSEUDO_ID = "__own_motorcycle__";

interface AddAppointmentFormValues {
  drivingLessonType: "" | DrivingLesson["drivingLessonType"] | "theoretischePruefung";
  dateTime: undefined | DateTime;
  durationInMinutes: number;
  startingPointId: string;
  theoryExamLocationId: string;
  practicalExamLocationId: string;
  instructorId: string;
  student: undefined | Student;
  drivingLicenseClass: "" | DrivingLicenseClass;
  carId: string;
  motorcycleId: string;
  trailerId: string;
  secondMotorcycleOrCarId: string;
}

interface BookDrivingLessonParams {
  drivingLessonType: "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt";
  examType?: never;
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration: { minutes: number };
  startingPointId: string;
  examLocationId?: never;
  resourceIds: Array<string>;
}

interface BookTheoryExamParams {
  drivingLessonType: "theoretischePruefung";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration?: never;
  startingPointId?: never;
  examLocationId: string;
}

interface BookPracticalExamParams {
  drivingLessonType: "praktischePruefung";
  instructorId: string;
  studentId: string;
  bookedTrainingId: string;
  dateTime: DateTime;
  duration?: never;
  startingPointId?: never;
  examLocationId: string;
  resourceIds: Array<string>;
}

function validate(
  formValues: AddAppointmentFormValues,
): BookDrivingLessonParams | BookTheoryExamParams | BookPracticalExamParams {
  const {
    dateTime,
    durationInMinutes,
    drivingLessonType,
    startingPointId,
    theoryExamLocationId,
    practicalExamLocationId,
    student,
    drivingLicenseClass,
    carId,
    motorcycleId,
    secondMotorcycleOrCarId,
    trailerId,
  } = formValues;
  if (!dateTime) {
    throw new Error(`Invalid dateTime: ${dateTime}`);
  }
  if (!drivingLessonType) {
    throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
  }
  if (!student) {
    throw new Error(`Invalid student: ${student}`);
  }
  if (drivingLessonType === "theoretischePruefung") {
    if (!theoryExamLocationId) {
      throw new Error(`Invalid theoryExamLocationId: ${theoryExamLocationId}`);
    }
    let instructorId = formValues.instructorId;
    if (!instructorId) {
      instructorId = student.instructorIds[0];
    }
    if (!instructorId) {
      throw new Error(`Student ${student.id} has no instructor`);
    }
    const relevantBookedTrainings = student.bookedTrainings.filter((it) => it.includesTheoryExam);
    // Prefer booked training for motorcycle if present ...
    const bookedTraining =
      relevantBookedTrainings.find((it) => isForMotorcycle(it.drivingLicenseClass)) ?? relevantBookedTrainings[0];
    if (!bookedTraining) {
      throw new Error(`Student ${student.id} has no booked training with a theory exam`);
    }
    return {
      drivingLessonType: "theoretischePruefung",
      studentId: student.id,
      instructorId,
      bookedTrainingId: bookedTraining.id,
      dateTime,
      examLocationId: theoryExamLocationId,
    } satisfies BookTheoryExamParams;
  } else {
    const instructorId = formValues.instructorId;
    if (!instructorId) {
      throw new Error(`Invalid instructorId: ${instructorId}`);
    }
    if (!drivingLicenseClass) {
      throw new Error(`Invalid drivingLicenseClass: ${drivingLicenseClass}`);
    }
    let resourceIds: Array<string>;
    if (isForMotorcycle(drivingLicenseClass)) {
      if (!motorcycleId) {
        throw new Error(`Invalid motorcycleId: ${motorcycleId}`);
      }
      const studentUsesOwnMotorcycle = motorcycleId === OWN_MOTORCYCLE_PSEUDO_ID;
      if (drivingLessonType === "praktischePruefung") {
        if (!carId) {
          throw new Error(`Invalid carId: ${carId}`);
        }
        resourceIds = studentUsesOwnMotorcycle ? [carId] : [motorcycleId, carId];
      } else {
        if (!secondMotorcycleOrCarId) {
          throw new Error(`Invalid secondMotorcycleOrCarId: ${secondMotorcycleOrCarId}`);
        }
        if (secondMotorcycleOrCarId === motorcycleId) {
          throw new Error(`secondMotorcycleOrCarId must not be equal to motorcycleId`);
        }
        resourceIds = studentUsesOwnMotorcycle ? [secondMotorcycleOrCarId] : [motorcycleId, secondMotorcycleOrCarId];
      }
    } else if (isForTrailer(drivingLicenseClass)) {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      if (!trailerId) {
        throw new Error(`Invalid trailerId: ${motorcycleId}`);
      }
      resourceIds = [carId, trailerId];
    } else {
      if (!carId) {
        throw new Error(`Invalid carId: ${carId}`);
      }
      resourceIds = [carId];
    }
    const bookedTraining = student.bookedTrainings.find((it) => it.drivingLicenseClass === drivingLicenseClass);
    if (!bookedTraining) {
      throw new Error(`Student ${student.id} has no booked training for driving license class ${drivingLicenseClass}`);
    }
    if (drivingLessonType === "praktischePruefung") {
      if (!practicalExamLocationId) {
        throw new Error(`Invalid practicalExamLocationId: ${practicalExamLocationId}`);
      }
      return {
        drivingLessonType: "praktischePruefung",
        instructorId,
        studentId: student.id,
        bookedTrainingId: bookedTraining.id,
        dateTime,
        examLocationId: practicalExamLocationId,
        resourceIds,
      } satisfies BookPracticalExamParams;
    } else {
      if (!["normal", "ueberlandfahrt", "autobahnfahrt", "nachtfahrt"].includes(drivingLessonType)) {
        throw new Error(`Invalid drivingLessonType: ${drivingLessonType}`);
      }
      if (!startingPointId) {
        throw new Error(`Invalid startingPointId: ${startingPointId}`);
      }
      if (!durationInMinutes) {
        throw new Error(`Invalid durationInMinutes: ${durationInMinutes}`);
      }
      return {
        drivingLessonType: drivingLessonType as "normal" | "ueberlandfahrt" | "autobahnfahrt" | "nachtfahrt",
        instructorId,
        studentId: student.id,
        bookedTrainingId: bookedTraining.id,
        dateTime,
        duration: { minutes: durationInMinutes },
        startingPointId,
        resourceIds,
      } satisfies BookDrivingLessonParams;
    }
  }
}

interface AddAppointmentDialogProps {
  open: boolean;
  onClose: () => void;
  instructor?: Instructor;
  drivingSchoolId?: string;
  mode?: "only exams";
}

interface AddAppointmentDialogType {
  (props: AddAppointmentDialogProps): ReactNode;

  initialDateTime?: DateTime;
  fullCalendarDateClickHandler: (openDialog: () => void) => (arg: DateClickArg) => void;
}

function _AddAppointmentDialog(props: AddAppointmentDialogProps) {
  const notify = useNotify();
  const refresh = useRefresh();
  const queryClient = useQueryClient();
  const initialDateTime = AddAppointmentDialog.initialDateTime;
  AddAppointmentDialog.initialDateTime = undefined; // <-- Side effect: reset AddAppointmentDialog.initialDateTime
  const defaultValues = {
    drivingLessonType: props.mode === "only exams" ? "" : "normal",
    dateTime: initialDateTime,
    durationInMinutes: 0,
    startingPointId: "",
    theoryExamLocationId: "",
    practicalExamLocationId: "",
    instructorId: props.instructor?.id ?? "",
    student: undefined,
    drivingLicenseClass: "",
    carId: "",
    motorcycleId: "",
    trailerId: "",
    secondMotorcycleOrCarId: "",
  } satisfies AddAppointmentFormValues;
  const formProps = useForm<AddAppointmentFormValues>({
    defaultValues:
      props.mode === "only exams"
        ? defaultValues
        : async () => {
            let durationInMinutes = defaultValues.durationInMinutes;
            if (props.instructor) {
              const { data } = await instructorRecommendationSettingsProvider.getOne("", { id: props.instructor.id });
              if (data) {
                durationInMinutes = data.preferredDurationMinsPerDrivingLessonType.normal[0];
              }
            }
            return { ...defaultValues, durationInMinutes };
          },
  });

  const drivingSchoolId = (props.instructor?.drivingSchoolId ?? props.drivingSchoolId) as string;
  const {
    drivingLessonType,
    dateTime,
    durationInMinutes,
    startingPointId,
    theoryExamLocationId,
    practicalExamLocationId,
    instructorId,
    student,
    drivingLicenseClass,
    carId,
    motorcycleId,
    trailerId,
    secondMotorcycleOrCarId,
  } = formProps.watch();

  const [price, setPrice] = useState<undefined | Money>();
  const [authorityFee, setAuthorityFee] = useState<undefined | Money>();
  const priceSuggestionCache = useRef<Record<string, ReturnType<typeof getPriceSuggestion>>>({}).current;
  useEffect(() => {
    setPrice(undefined);
    setAuthorityFee(undefined);
    let params: undefined | ReturnType<typeof validate>;
    try {
      params = validate({
        drivingLessonType,
        dateTime,
        durationInMinutes,
        startingPointId,
        theoryExamLocationId,
        practicalExamLocationId,
        instructorId,
        student,
        drivingLicenseClass,
        carId,
        motorcycleId,
        trailerId,
        secondMotorcycleOrCarId,
      });
    } catch (error) {
      // form is invalid
      return;
    }
    void (async () => {
      try {
        const request = GetPriceSuggestionRequestSchema.parse({
          eventType: "DrivingLesson",
          drivingLessonType,
          instructorUid: params.instructorId,
          studentUid: params.studentId,
          bookedTrainingId: params.bookedTrainingId,
          start: params.dateTime,
          ...(params.duration ? { end: params.dateTime.plus(params.duration) } : {}),
        });
        const cacheKey = JSON.stringify(request);
        let promise = priceSuggestionCache[cacheKey];
        if (!promise) {
          priceSuggestionCache[cacheKey] = promise = getPriceSuggestion(request);
        }
        const priceSuggestion = await promise;
        let priceSuggestionMatchesFormValues = false;
        try {
          const params2 = validate(formProps.getValues());
          priceSuggestionMatchesFormValues =
            params.drivingLessonType === params2.drivingLessonType &&
            params.instructorId === params2.instructorId &&
            params.studentId === params2.studentId &&
            params.bookedTrainingId === params2.bookedTrainingId &&
            params.dateTime === params2.dateTime &&
            params.duration?.minutes === params2.duration?.minutes;
        } catch {
          // Ignored: form is invalid
          return;
        }
        if (priceSuggestionMatchesFormValues) {
          setPrice(priceSuggestion.price);
          setAuthorityFee(priceSuggestion.authorityFee);
        }
      } catch (error) {
        console.error("Failed to get price suggestion", error);
      }
    })();
  }, [
    formProps,
    drivingLessonType,
    dateTime,
    durationInMinutes,
    instructorId,
    student?.id,
    drivingLicenseClass,
    carId,
    motorcycleId,
    trailerId,
    secondMotorcycleOrCarId,
    startingPointId,
    theoryExamLocationId,
    practicalExamLocationId,
  ]);

  const { data: instructor } = useGetOne("instructors", { id: instructorId }, { enabled: !!instructorId });

  const onSubmit = async (formValues: AddAppointmentFormValues) => {
    try {
      const params = validate(formValues);
      const studentRsvp = params.dateTime < DateTime.now() ? "accepted" : "pending";
      if (params.drivingLessonType === "theoretischePruefung" || params.drivingLessonType === "praktischePruefung") {
        const { drivingLessonType, examLocationId, ...rest } = params;
        const examType = drivingLessonType === "theoretischePruefung" ? "theoryExam" : "practicalExam";
        const { data: examLocation } = await examLocationsProvider.getOne("examLocations", { id: examLocationId });
        const location = examLocation.postalAddress;
        await bookExam({ ...rest, examType, location, studentRsvp });
        notify(
          studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Prüfungstermin erfolgreich eingetragen",
          { type: "success" },
        );
      } else {
        const { startingPointId, ...rest } = params;
        const { data: startingPoint } = await startingPointsProvider.getOne("startingPoints", { id: startingPointId });
        const location = startingPoint.postalAddress;
        await bookDrivingLesson({ ...rest, location, studentRsvp });
        notify(studentRsvp === "pending" ? "Einladung erfolgreich versandt" : "Fahrstunde erfolgreich eingetragen", {
          type: "success",
        });
      }
      props.onClose();
      await queryClient.invalidateQueries(["recommendations", "student"]);
      await queryClient.invalidateQueries(["calendarEvents"]);
      await queryClient.invalidateQueries(["calendarEventHistory"]);
      refresh();
    } catch (error) {
      if (!(await handleBookingError(error, formValues.student, notify))) {
        console.error("Failed to invite student", error);
        notify("Fehler beim Versenden der Einladung", { type: "error" });
      }
    }
  };

  const drivingLessonForm = (
    <WhenFormIsInitialized>
      <Stack gap="1em">
        <DateTimeInputField sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }} />
        <DurationInputField />
        <DrivingLessonTypeInputField />
        <StudentInputField instructorId={instructorId} />
        {!student && <_StudentSuggestions />}
        {student && <DrivingLicenseClassInputField label="Ausbildung für Führerscheinklasse" />}
        {student && <ResourceInputFields instructor={instructor} />}
        {student && <StartingPointInputField instructor={instructor} student={student} />}
        {/* [UX] Render a non-breaking space if no price is available to prevent the dialog from jumping ... */}
        {!price && <Typography>{"\u00A0"}</Typography>}
        {price && <Typography>Preis: {moneyFormatter.format(price.amount)}</Typography>}
      </Stack>
    </WhenFormIsInitialized>
  );

  const renderingTheoryExamForm = drivingLessonType === "theoretischePruefung";
  const renderingPracticalExamForm = drivingLessonType === "praktischePruefung";

  const examForm = (
    <Stack gap="1em">
      <DateTimeInputField sx={{ mt: "5px" /* <-- prevents that the input label is truncated */ }} />
      <ExamTypeInputField />
      {renderingPracticalExamForm && !props.instructor && <InstructorInputField drivingSchoolId={drivingSchoolId} />}
      {renderingTheoryExamForm && props.instructor && <StudentInputField instructorId={instructorId} />}
      {renderingTheoryExamForm && !props.instructor && <StudentInputField drivingSchoolId={drivingSchoolId} />}
      {renderingPracticalExamForm && instructorId && <StudentInputField instructorId={instructorId} />}
      {renderingPracticalExamForm && student && <DrivingLicenseClassInputField />}
      {renderingPracticalExamForm && student && <ResourceInputFields instructor={instructor} />}
      {renderingTheoryExamForm && student && <TheoryExamLocationInputField drivingSchoolId={drivingSchoolId} />}
      {renderingPracticalExamForm && student && <PracticalExamLocationInputField drivingSchoolId={drivingSchoolId} />}
      <Row>
        {/* [UX] Render a non-breaking space if no price is available to prevent the dialog from jumping ... */}
        {!(price || authorityFee) && <Typography>{"\u00A0"}</Typography>}
        {price && <Typography>Preis: {moneyFormatter.format(price.amount)}</Typography>}
        {price && authorityFee && <Typography>{"\u00A0+\u00A0"}</Typography>}
        {authorityFee && <Typography>TÜV-/DEKRA-Gebühr: {moneyFormatter.format(authorityFee.amount)}</Typography>}
      </Row>
    </Stack>
  );

  return (
    <FormProvider {...formProps}>
      <Dialog open={props.open} onClose={props.onClose}>
        <DialogTitle>{`Neuer ${props.mode === "only exams" ? "Prüfungstermin" : "Termin"}`}</DialogTitle>
        <DialogCloseButton onClose={props.onClose} disabled={formProps.formState.isSubmitting} />
        <form onSubmit={formProps.handleSubmit(onSubmit)}>
          <DialogContent sx={{ minWidth: "400px" }}>
            {props.mode === "only exams" && examForm}
            {props.mode !== "only exams" && (
              <Tabs sx={{ m: 0 }} syncWithLocation={false}>
                <Tab label="Fahrstunde">{drivingLessonForm}</Tab>
                <Tab label="Prüfung">{examForm}</Tab>
              </Tabs>
            )}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              variant="contained"
              loading={formProps.formState.isSubmitting}
              disabled={formProps.formState.isLoading || formProps.formState.isSubmitting}
              onClick={formProps.handleSubmit(onSubmit)}
            >
              {dateTime && dateTime < DateTime.now() ? "Termin eintragen" : "Einladung senden"}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </FormProvider>
  );
}

export const AddAppointmentDialog: AddAppointmentDialogType = _AddAppointmentDialog as any;
AddAppointmentDialog.fullCalendarDateClickHandler =
  (openDialog: () => void) =>
  ({ dateStr, dayEl, jsEvent }) => {
    try {
      let dateTime = DateTime.fromISO(dateStr, { zone: "Europe/Berlin" });
      // We need to adapt dateTime here, because sometimes FullCalendar does not pass quite the correct date ...
      // Furthermore we add 15 minutes if the click was in the lower half of the 30 min time slot ...
      const { top, height } = dayEl.getBoundingClientRect();
      const h = ((24 - 6) / height) * (jsEvent.clientY - top);
      const m = (h - Math.floor(h)) * 60;
      if (dateTime.get("minute") === 0) {
        if (m >= 25) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 10) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else if (dateTime.get("minute") === 30) {
        if (m >= 55) {
          dateTime = dateTime.plus({ minutes: 30 });
        } else if (m >= 40) {
          dateTime = dateTime.plus({ minutes: 15 });
        }
      } else {
        throw new Error(`Unexpected dateTime.get("minute"): ${dateTime.get("minute")}`);
      }
      AddAppointmentDialog.initialDateTime = dateTime;
      openDialog();
    } catch (error) {
      console.error("Failed to handle dateClick", error);
    }
  };

function WhenFormIsInitialized({ children }: { children: ReactNode }) {
  const { formState } = useFormContext();
  return formState.isLoading ? null : children;
}

function DateTimeInputField({ sx }: { sx?: SxProps<Theme> }) {
  const { control, watch } = useFormContext<AddAppointmentFormValues>();
  const drivingLessonType = watch("drivingLessonType");
  const allowPastDates =
    grants.includes("addExamInThePast") &&
    (drivingLessonType === "theoretischePruefung" || drivingLessonType === "praktischePruefung");
  return (
    <Controller
      name="dateTime"
      control={control}
      render={({ field, formState }) => {
        const error = formState.errors.dateTime?.message;
        return (
          <FormControl>
            <DateTimePicker
              sx={sx}
              label="Datum & Uhrzeit"
              inputRef={field.ref}
              ampm={false}
              value={field.value ?? null}
              disabled={formState.isSubmitting}
              minutesStep={5}
              slotProps={
                error
                  ? {
                      textField: {
                        error: true,
                        helperText: error,
                        onBlur: field.onBlur,
                      },
                    }
                  : undefined
              }
              onChange={(value) => field.onChange({ target: { value } })}
            />
          </FormControl>
        );
      }}
      rules={{
        validate: (value) => {
          if (!value) {
            return "Bitte wähle das Datum und die Uhrzeit aus.";
          } else if (value.hour === 0 && value.minute === 0) {
            return "Bitte wähle noch die Uhrzeit aus.";
          } else if (value < DateTime.now() && !allowPastDates) {
            return "Bitte wähle einen Zeitpunkt in der Zukunft aus.";
          } else {
            return true;
          }
        },
      }}
    />
  );
}

const _durationInMinutesOptions: Array<[number, string]> = [
  [15, "15 Minuten"],
  [30, "30 Minuten"],
  [45, "45 Minuten"],
  [60, "60 Minuten"],
  [75, "75 Minuten"],
  [90, "90 Minuten"],
  [135, "135 Minuten"],
  [180, "180 Minuten"],
  [225, "225 Minuten"],
];

function DurationInputField() {
  return (
    <_Select
      field="durationInMinutes"
      label="Dauer"
      options={_durationInMinutesOptions}
      validate={(value) => (value ? true : "Bitte wähle die Dauer der Fahrstunde aus.")}
    />
  );
}

const _drivingLessonTypeOptions: Array<[string, string]> = [
  ["normal", "normale Übungsstunde"],
  ["autobahnfahrt", "Autobahnfahrt"],
  ["nachtfahrt", "Nachtfahrt"],
  ["ueberlandfahrt", "Überlandfahrt"],
];

function DrivingLessonTypeInputField() {
  return (
    <_Select
      field="drivingLessonType"
      label="Art"
      options={_drivingLessonTypeOptions}
      validate={(value) => (value ? true : "Bitte wähle die Art der Fahrstunde aus.")}
    />
  );
}

const _examTypeOptions: Array<[string, string]> = [
  ["theoretischePruefung", "Theorieprüfung"],
  ["praktischePruefung", "Praktische Prüfung"],
];

function ExamTypeInputField() {
  return (
    <_Select
      field="drivingLessonType"
      label="Art der Prüfung"
      options={_examTypeOptions}
      validate={(value) => (value ? true : "Bitte wähle die Art der Prüfung aus.")}
    />
  );
}

function InstructorInputField(props: { drivingSchoolId: string }) {
  const { data: instructors } = useGetManyReference<Instructor>("instructors", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <_Select
      field="instructorId"
      label="Fahrlehrer"
      options={instructors?.map((instructor) => [instructor.id, instructor.name])}
      validate={(value) => (value ? true : "Bitte wähle den Fahrlehrer aus.")}
    />
  );
}

function StudentInputField(props: { drivingSchoolId?: string; instructorId?: string }) {
  const { control, setValue, watch } = useFormContext<AddAppointmentFormValues>();
  const student = watch("student");
  return (
    <Controller
      name="student"
      control={control}
      render={({ field, formState }) => (
        <StudentSelect
          inputRef={field.ref}
          label="Fahrschüler"
          size="medium"
          disabled={formState.isSubmitting}
          drivingSchoolId={props.drivingSchoolId}
          instructorId={props.instructorId}
          value={student}
          onChange={(value) => {
            field.onChange({ target: { value } });
            if (value) {
              setValue("drivingLicenseClass", value.activeOrMostRecentBookedTraining.drivingLicenseClass);
            }
          }}
          onBlur={field.onBlur}
          error={formState.errors.student?.message}
        />
      )}
      rules={{
        validate: (value?: Student) => {
          return value ? true : "Bitte wähle den Fahrschüler aus.";
        },
      }}
    />
  );
}

function DrivingLicenseClassInputField({ label = "Führerscheinklasse" }: { label?: string }) {
  const { watch } = useFormContext<AddAppointmentFormValues>();
  const student = watch("student");
  const drivingLicenseClassOptions = useMemo(() => {
    if (student) {
      const unfinishedBookedTrainings = student.bookedTrainings.filter(
        (it) => !Object.values(it.kpis?.practicalExams ?? {}).some((it) => it.result === "passed"),
      );
      return unfinishedBookedTrainings.map((it) => [it.drivingLicenseClass, it.drivingLicenseClass]);
    }
    return [];
  }, [student]) as Array<[DrivingLicenseClass, string]>;
  if (drivingLicenseClassOptions.length <= 1) {
    return null;
  }
  return (
    <_Select
      field="drivingLicenseClass"
      label={label}
      options={drivingLicenseClassOptions}
      validate={(value) => (value ? true : "Bitte wähle die Führerscheinklasse aus.")}
    />
  );
}

function ResourceInputFields({ instructor }: { instructor: Instructor }) {
  const { watch } = useFormContext<AddAppointmentFormValues>();
  const student = watch("student");
  const drivingLicenseClass = watch("drivingLicenseClass");
  const drivingLessonType = watch("drivingLessonType");

  if (!(student && drivingLicenseClass && drivingLessonType)) {
    return null;
  }

  if (isForMotorcycle(drivingLicenseClass)) {
    if (drivingLessonType === "praktischePruefung") {
      return (
        <>
          <MotorcycleInputField label="Motorrad für Fahrschüler" instructorId={instructor.id} />
          <CarInputField label="Auto für Fahrlehrer und Prüfer" instructorId={instructor.id} />
        </>
      );
    } else {
      return (
        <>
          <MotorcycleInputField label="Motorrad für Fahrschüler" instructorId={instructor.id} />
          <SecondCarOrMotorcycleInputField label="Fahrzeug für Fahrlehrer" instructorId={instructor.id} />
        </>
      );
    }
  }

  return (
    <>
      <CarInputField label="Auto" instructorId={instructor.id} />
      {isForTrailer(drivingLicenseClass) && <TrailerInputField label="Anhänger" instructorId={instructor.id} />}
    </>
  );
}

function _formatGearType(gearType: "manual" | "automatic" | "electric") {
  if (gearType === "manual") {
    return "Schaltwagen";
  } else if (gearType === "automatic") {
    return "Automatik";
  } else if (gearType === "electric") {
    return "Elektrisch";
  } else {
    return gearType;
  }
}

function CarInputField(props: { label: string; instructorId: Identifier }) {
  const { data: cars } = useGetManyReference<Car>("cars", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <_Select
      field="carId"
      label={props.label}
      options={cars?.map((car) => [car.id, `${car.name} (${_formatGearType(car.car.gearType)})`])}
      validate={(value) => (value ? true : "Bitte wähle ein Auto aus.")}
    />
  );
}

function MotorcycleInputField(props: { label: string; instructorId: Identifier }) {
  const { data: motorcycles } = useGetManyReference<Motorcycle>("motorcycles", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  const options: undefined | Array<[string, string]> = motorcycles && [
    [OWN_MOTORCYCLE_PSEUDO_ID, "Eigenes Motorrad"],
    ...(motorcycles.map((it) => [it.id, it.name]) as Array<[string, string]>),
  ];
  return (
    <_Select
      field="motorcycleId"
      label={props.label}
      options={options}
      validate={(value) => (value ? true : "Bitte wähle ein Motorrad aus.")}
    />
  );
}

function SecondCarOrMotorcycleInputField(props: { label: string; instructorId: Identifier }) {
  const { watch } = useFormContext<AddAppointmentFormValues>();
  const motorcycleId = watch("motorcycleId");
  const { data: motorcycles } = useGetManyReference<Motorcycle>("motorcycles", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  const { data: cars } = useGetManyReference<Car>("cars", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  const options =
    motorcycles && cars && ([...motorcycles, ...cars].map((it) => [it.id, it.name]) as Array<[string, string]>);
  return (
    <_Select
      field="secondMotorcycleOrCarId"
      label={props.label}
      options={options}
      validate={(value) =>
        !value
          ? "Bitte wähle ein Fahrzeug aus."
          : value === motorcycleId
          ? "Bitte wähle ein anderes Fahrzeug aus."
          : true
      }
    />
  );
}

function TrailerInputField(props: { label: string; instructorId: Identifier }) {
  const { data: trailers } = useGetManyReference<Trailer>("trailers", {
    target: "entitledUserId",
    id: props.instructorId,
    filter: { withDeleted: false },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <_Select
      field="trailerId"
      label={props.label}
      options={trailers?.map((trailer) => [trailer.id, trailer.name])}
      validate={(value) => (value ? true : "Bitte wähle einen Anhänger aus.")}
    />
  );
}

function StartingPointInputField({ instructor, student }: { instructor: Instructor; student: Student }) {
  const { data: startingPoints } = useGetList<StartingPoint>("startingPoints", {
    sort: { field: "__branches__student_address__favorite_locations__", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
    meta: { instructor, student },
  });
  return (
    <_Select
      field="startingPointId"
      label="Startpunkt"
      options={startingPoints?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Startpunkt aus.")}
    />
  );
}

function TheoryExamLocationInputField(props: { drivingSchoolId: string }) {
  const { data: examLocations } = useGetManyReference<ExamLocation>("examLocations", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    filter: { types: "theoryExam" satisfies ExamType },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <_Select
      field="theoryExamLocationId"
      label="Ort"
      options={examLocations?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Ort aus.")}
    />
  );
}

function PracticalExamLocationInputField(props: { drivingSchoolId: string }) {
  const { data: examLocations } = useGetManyReference<ExamLocation>("examLocations", {
    target: "drivingSchoolId",
    id: props.drivingSchoolId,
    filter: { types: "practicalExam" satisfies ExamType },
    sort: { field: "name", order: "ASC" },
    pagination: { page: 1, perPage: 999 },
  });
  return (
    <_Select
      field="practicalExamLocationId"
      label="Startpunkt"
      options={examLocations?.map((it) => [
        it.id,
        `${it.name} (${renderAddress(it.postalAddress, { oneLine: true })})`,
      ])}
      validate={(value) => (value ? true : "Bitte wähle den Startpunkt aus.")}
    />
  );
}

let _selectLabelIdCounter = 1;

function _Select<T extends string | number>({
  field,
  label,
  options,
  validate,
}: {
  field: keyof AddAppointmentFormValues;
  label: string;
  sx?: SxProps<Theme>;
  options: undefined | Array<[T, string]>;
  validate: Validate<T, AddAppointmentFormValues>;
}) {
  const labelId = useMemo(() => `Select-Label-${_selectLabelIdCounter++}`, []);
  const { register, formState, getValues } = useFormContext<AddAppointmentFormValues>();
  const { ref, ...fieldProps } = register(field, { validate: validate as any });

  const error = formState.errors[field]?.message;
  return (
    <FormControl>
      <InputLabel id={labelId} error={!!error}>
        {label}
      </InputLabel>
      <Select
        {...fieldProps}
        label={label}
        labelId={labelId}
        inputRef={ref}
        value={getValues(field)}
        error={!!error}
        disabled={formState.isSubmitting}
      >
        {(!options || options.length === 0) && <LinearProgress sx={{ ml: "1em" }} />}
        {options?.map(([value, label]) => (
          <MenuItem key={label} value={value}>
            {label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText error>{error}</FormHelperText>}
    </FormControl>
  );
}

function _StudentSuggestions() {
  const [{ drivingSchoolId }] = useAutovioContext();
  const dateTime = useWatch({ name: "dateTime" });
  const instructorId = useWatch({ name: "instructorId" });
  if (!drivingSchoolId || !dateTime) {
    return null;
  }
  return <__StudentSuggestions drivingSchoolId={drivingSchoolId} dateTime={dateTime} instructorId={instructorId} />;
}

function __StudentSuggestions({
  drivingSchoolId,
  dateTime,
  instructorId,
}: {
  drivingSchoolId: string;
  dateTime: DateTime;
  instructorId?: string;
}) {
  const drivingLessons: Array<DrivingLesson> = [];
  let isLoading = false;
  for (const daysAgo of [7, 14, 21, 28]) {
    // A general rule is: Hooks should not be called in if statements or loops.
    // But this is one exception to this rule, which is safe, because the number
    // of iterations is always the same.
    const { data } = useGetManyReference("drivingLessons", {
      target: "drivingSchoolId",
      id: drivingSchoolId,
      filter: {
        ...(instructorId ? { instructorId } : {}),
        dateRange: Interval.fromDateTimes(
          dateTime.minus({ days: daysAgo, minutes: 90 }),
          dateTime.minus({ days: daysAgo }).plus({ minute: 90 }),
        ),
      },
      pagination: { page: 1, perPage: 999 },
      sort: { field: "start", order: "ASC" },
    });
    if (data) {
      drivingLessons.push(...data);
    } else {
      isLoading = true;
    }
  }
  const drivingLessonsByStudentUid: { [studentUid: string]: Array<DrivingLesson> } = {};
  for (const drivingLesson of drivingLessons) {
    const a = drivingLessonsByStudentUid[drivingLesson.student.uid];
    if (a) {
      a.push(drivingLesson);
    } else {
      drivingLessonsByStudentUid[drivingLesson.student.uid] = [drivingLesson];
    }
  }
  return (
    <div>
      <h4 style={{ color: autovioColors.grey }}>Vorschläge</h4>
      <div>
        {isLoading && <LinearProgress />}
        {!isLoading && drivingLessons.length === 0 && (
          <>
            {"Keine Fahrstunden in den letzten 4 Wochen"}
            <br />
            {`um ${formatTime(dateTime)} Uhr ±90 Minuten gefunden.`}
          </>
        )}
        {Object.entries(drivingLessonsByStudentUid).map(([studentUid, drivingLessons]) => (
          <_StudentSuggestion
            key={studentUid}
            dateTime={dateTime}
            studentUid={studentUid}
            drivingLessons={drivingLessons}
          />
        ))}
      </div>
    </div>
  );
}

function _StudentSuggestion({
  dateTime,
  studentUid,
  drivingLessons,
}: {
  dateTime: DateTime;
  studentUid: string;
  drivingLessons: Array<DrivingLesson>;
}) {
  const { data: student } = useGetOne<Student>("students", { id: studentUid });
  const { setValue } = useFormContext();
  if (!student || student.status !== "active") {
    return null;
  }
  const drivingLesson = drivingLessons[0];
  const weeksAgo = Math.floor(dateTime.diff(drivingLesson.start, "weeks").weeks);
  return (
    <Paper
      sx={{
        border: "0.8px solid rgba(0, 0, 0, 0.23)",
        borderRadius: "20px",
        padding: "15px",
        cursor: "pointer",
        ":hover": {
          backgroundColor: autovioColors.greyUltraLight,
        },
      }}
      onClick={() => {
        setValue("student", student);
        setValue("drivingLicenseClass", student.activeOrMostRecentBookedTraining.drivingLicenseClass);
      }}
    >
      <Row alignItems="center" spacing={1}>
        <Avatar src={student?.avatarOverrideUrl ?? student?.avatarUrl} />
        <Column sx={{ flex: 1 }}>
          <Typography variant="body2" fontWeight="bold">
            {student?.name}
          </Typography>
          <Typography variant="body2">
            {`Fahrstunde vor ${weeksAgo} ${weeksAgo === 1 ? "Woche" : "Wochen"} um ${formatTime(
              drivingLesson.start,
            )} Uhr`}
          </Typography>
        </Column>
        <IconButton
          onClick={(event) => {
            window.open(`/students/${studentUid}`, "_blank");
            event.stopPropagation();
            return false;
          }}
        >
          <OpenInNewIcon htmlColor={autovioColors.green} />
        </IconButton>
      </Row>
    </Paper>
  );
}
