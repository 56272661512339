import { defaultTheme } from "react-admin";
import { red } from "@mui/material/colors";
import { SxProps, Theme } from "@mui/material";
import { VerboseStudentStatus } from "../providers/studentsProvider";

// See https://app.zeplin.io/styleguide/617a71c98b49c0bace1a9863/colors
export const autovioColors = {
  black: "#11130e",
  blue: "#007aff",
  green: "#74a089",
  greenLight: "#b9cfc4",
  greenUltraLight: "#e3ece7",
  greenDark: "#315340",
  grey: "#888888",
  greyLight: "#d8d8d8",
  greyUltraLight: "#f2f2f2",
  orange: "#f39557",
  orangeUltraLight: "#fdefe6",
  red: "#e87b7b",
  redLight: "#f6aaaa",
  redUltraLight: "#fae5e5",
  white: "#ffffff",
  yellow: "#f6ca00",
  yellowUltraLight: "#fdf4cc",
  violet: "#d483b7",
  violetUltraLight: "#f9eef5",
  borderGrey: "#e5e5e5",
};

export const studentStatusColors: Record<VerboseStudentStatus, string> = {
  Abgebrochen: "#888888",
  Fertig: "#888888",
  Inaktiv: "#888888",
  Unbekannt: "#888888",
  Aktiv: "#74A089",
  "Aktiv (Grundbetrag offen)": "#74A089",
  "Aktiv (Kein Zahlungsmittel)": "#74A089",
  "Aktiv (Onboarding fehlt)": "#74A089",
  Pausiert: "#B9CFC4",
  "Offene Zahlungen": "#E87B7B",
};

export const fullWidthTabsStyle = {
  background: autovioColors.greyUltraLight,
  alignItems: "center",
  borderRadius: "20px",
  minHeight: "0px",
  width: "100%",
};

export const overviewCardStyle = {
  border: "1px solid #e5e5e5",
  background: "white",
  width: "100%",
  borderRadius: "12px",
  padding: "25px 30px",
};

export const dotStyle = {
  width: 12,
  height: 12,
  borderRadius: 8,
  border: "2.4px solid #E5E5E5",
  boxShadow: "none",
};

const linkButtonStyle: SxProps<Theme> = {
  padding: "6px 16px 6px 16px",
  minHeight: "32px",
  height: "32px",
  textTransform: "none",
  "&:hover": {
    backgroundColor: autovioColors.greenUltraLight,
  },
};

export const backofficeTheme = {
  ...defaultTheme,
  linkButtonStyle, // ... added here for easy access, because the custom "link" variant for the MuiButton does not work
  shape: {
    borderRadius: 20,
  },
  palette: {
    background: {
      default: autovioColors.white,
    },
    primary: {
      main: "#74a089",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#11130e",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          "&:hover": {
            backgroundColor: "#d65e0f", // <-- dark orange (not a AUTOVIO color yet)
          },
          backgroundColor: autovioColors.orange,
          color: autovioColors.white,
        },
      },
      // See https://mui.com/material-ui/customization/theme-components/#creating-new-component-variants
      variants: [
        {
          props: {
            variant: "link",
            style: linkButtonStyle,
          },
        },
      ],
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          boxShadow: "none",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          marginRight: "40px" /* <-- to prevent the title from overlapping the DialogCloseButton */,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0 24px 24px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 24px 24px 24px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderRadius: "8px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "6px 16px 6px 16px",
          minHeight: "32px !important",
          height: "32px !important",
          borderRadius: "16px",
          color: autovioColors.grey,
          textTransform: "none",
          "&:hover": {
            backgroundColor: autovioColors.greenUltraLight,
          },
          "&.Mui-selected": {
            color: "white !important",
          },
          maxWidth: "none",
        },
      },
    },
    RaToolbar: {
      styleOverrides: {
        root: {
          backgroundColor: autovioColors.white,
        },
      },
    },
    MuiMenuItem: {
      marginLeft: 2,
      marginRight: 2,
      "&:hover": {
        backgroundColor: "transparent",
      },
      "&.Mui-selected": {
        backgroundColor: "transparent !important",
        color: "inherit !important",
      },
      "&.Mui-selected:hover": {
        backgroundColor: "transparent",
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: 14,
          minWidth: 200,
          backgroundColor: autovioColors.white,
        },
      },
    },
  },
};
