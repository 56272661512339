import { Box } from "@mui/material";
import { overviewCardStyle } from "../../../misc/backofficeTheme";
import { NotesAndDocuments } from "../../../lists/NotesAndDocuments.js";

export function NotesAndDocumentsCard() {
  return (
    <Box sx={overviewCardStyle}>
      <NotesAndDocuments source="drivingSchool" />
    </Box>
  );
}
