import { GetOneParams, GetOneResult } from "react-admin";
import { AbstractProvider } from "./abstractProvider";
import { instructorsProvider } from "./instructorsProvider";
import { studentsProvider } from "./studentsProvider";
import { getAuthenticatedServerClient } from "../api/server.api";

export interface User {
  id: string;
  name: string;
  avatarUrl?: string;
}

class UsersProvider extends AbstractProvider<User> {
  async getOne(_resource: string, { id }: GetOneParams): Promise<GetOneResult<User>> {
    if (id === "backend") {
      return { data: { id, name: "AUTOVIO" } };
    }
    const cachedUser = instructorsProvider.getOneFromCache(id) || studentsProvider.getOneFromCache(id);
    if (cachedUser) {
      return { data: { id, name: cachedUser.name, avatarUrl: cachedUser.avatarUrl } };
    }
    const serverClient = await getAuthenticatedServerClient();
    const userDto = await serverClient.getUser({ params: { id } });
    return { data: { id, name: `${userDto.firstName} ${userDto.lastName}`.trim(), avatarUrl: userDto.avatarUrl } };
  }
}

export const usersProvider = new UsersProvider();
