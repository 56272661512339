import { FilterOperationType, type IFilter } from "@chax-at/prisma-filter-common";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useCallback, useRef, useState } from "react";
import { PaginationPayload, useDataProvider, useRedirect } from "react-admin";
import { useHotkeys } from "react-hotkeys-hook";
import { getAuthenticatedServerClient, type StudentDto, type StudentFilterDto } from "../api/server.api.js";
import { useDebouncedEffect } from "../hooks/useDebouncedEffect.js";
import { DrivingSchoolSearchIcon } from "../icons/DrivingSchoolSearchIcon";
import { InstructorSearchIcon } from "../icons/InstructorSearchIcon";
import { StudentSearchIcon } from "../icons/StudentSearchIcon";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { Instructor, isInstructor } from "../providers/instructorsProvider";
import { isServerStudent, isStudent, Student } from "../providers/studentsProvider";
import { autovioColors } from "./backofficeTheme";
import { Delayed } from "./Delayed.js";

export default function GlobalSearchBox() {
  const { open, setOpen, value, setValue, inputValue, setInputValue, loading, options } = useAutocompleteSearch();
  const redirect = useRedirect();

  return (
    <Autocomplete
      openOnFocus
      blurOnSelect
      autoHighlight
      clearOnEscape
      value={value}
      inputValue={inputValue}
      onInputChange={(_, newInputValue) => {
        setInputValue(newInputValue);
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      getOptionLabel={(option) => option.name}
      options={options}
      loading={loading}
      loadingText="Lädt..."
      noOptionsText="Keine Ergebnisse"
      openText="Öffnen"
      clearText="Löschen"
      closeText="Schließen"
      groupBy={(option) => option.group}
      onChange={(_, value, reason) => {
        if (value && reason === "selectOption") {
          redirect("edit", value.resource, value.id);
          setValue(null);
          setInputValue("");
        } else {
          setValue(value);
        }
      }}
      renderOption={(props, option, { inputValue }) => {
        // Render the results with match highlighting
        // The highlighted parts are shown in regular font weight, the rest in bold
        const matches = match(option.name, inputValue, { insideWords: true });
        const parts = parse(option.name, matches);

        const icon =
          option.group === "Fahrschulen" ? (
            <DrivingSchoolSearchIcon />
          ) : option.group === "Fahrlehrer" ? (
            <InstructorSearchIcon />
          ) : option.group === "Fahrschüler" ? (
            <StudentSearchIcon />
          ) : null;
        return (
          <li {...props} key={option.id}>
            <Stack direction="row" style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              {icon}
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 400 : 700,
                      fontSize: 14,
                    }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            </Stack>
          </li>
        );
      }}
      renderInput={(params) => <SearchInput {...params} loading={loading} />}
    />
  );
}

function SearchInput(props: AutocompleteRenderInputParams & { loading: boolean }) {
  const { loading, ...params } = props;
  const inputRef = useRef<HTMLInputElement>(null);
  const userAgent = navigator.userAgent;
  const isMacOS = /Mac OS X/.test(userAgent);
  useHotkeys("ctrl+f", () => inputRef.current?.focus(), { preventDefault: true });
  useHotkeys("esc", () => inputRef.current?.blur(), { enableOnFormTags: true });
  const { greyUltraLight, grey } = autovioColors;
  const searchFieldPlaceholder = (
    <InputAdornment position="start" sx={{ color: grey }}>
      <Typography sx={{ fontSize: 14 }}>Suche</Typography>
      <Chip
        sx={{
          background: greyUltraLight,
          color: "#bbb",
          marginLeft: 1,
          fontWeight: "bold",
          fontSize: 10,
          height: 23,
        }}
        label={isMacOS ? "CTRL + F" : "STRG + F"}
      />
    </InputAdornment>
  );
  const [placeholder, setPlaceholder] = useState<JSX.Element | string>(searchFieldPlaceholder);

  const handleFocus = () => {
    setPlaceholder("");
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setPlaceholder(searchFieldPlaceholder);
    }
  };

  const handleReset = () => {
    if (inputRef.current?.value) inputRef.current.value = "";
  };

  return (
    <TextField
      {...params}
      sx={{
        border: "1px solid #d8d8d8 !important",
        paddingRight: "-20px",
        borderRadius: 8,
        "& .MuiInputBase-input::placeholder": {
          color: "transparent",
        },
        ":hover": {
          border: "1px solid #9b9b9b !important",
        },
      }}
      label=""
      placeholder="Suche"
      inputRef={inputRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      size="small"
      InputProps={{
        ...params.InputProps,
        startAdornment: (
          <Box sx={{ paddingLeft: "11px", display: "flex", alignItems: "center", gap: "9px" }}>
            <SearchIcon sx={{ fontSize: 20, color: "#9b9b9b" }} />
            {placeholder}
          </Box>
        ),
        endAdornment: (
          <>
            {inputRef.current?.value && (
              <>
                <Delayed ms={500}>
                  <InputAdornment position="end">{loading && <CircularProgress size={20} />}</InputAdornment>
                </Delayed>
                <InputAdornment position="end">
                  <IconButton
                    sx={{
                      height: 32,
                      width: 32,
                      marginRight: -40,
                    }}
                    onClick={handleReset}
                  >
                    <CloseIcon sx={{ fontSize: 20, color: "#9b9b9b" }} />
                  </IconButton>
                </InputAdornment>
              </>
            )}
          </>
        ),
      }}
    />
  );
}

function useAutocompleteSearch(): {
  open: boolean;
  setOpen: (open: boolean) => void;
  value: SearchOption | null;
  setValue: (value: SearchOption | null) => void;
  inputValue: string;
  setInputValue: (value: string) => void;
  loading: boolean;
  options: SearchOption[];
} {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<SearchOption | null>(null);
  const [inputValue, setInputValue] = useState("");

  const [options, setOptions] = useState<SearchOption[]>([]);
  const [loading, setLoading] = useState(true);

  const dataProvider = useDataProvider();

  const fetchData = useCallback(async () => {
    setLoading(true);

    const serverClient = await getAuthenticatedServerClient();
    const [students, instructors, drivingSchools] = await Promise.all([
      serverClient.listStudents({
        queries: {
          filter: [{ field: "!name", type: FilterOperationType.IStartsWith, value: inputValue.trim() }],
          limit: 100,
        } satisfies IFilter<StudentFilterDto>,
      }),
      dataProvider.getList<Instructor>("instructors", {
        pagination: {} as PaginationPayload,
        sort: { field: "name", order: "ASC" },
        filter: {},
      }),
      dataProvider.getList<DrivingSchool>("drivingSchools", {
        pagination: {} as PaginationPayload,
        sort: { field: "name", order: "ASC" },
        filter: {},
      }),
    ]);

    setOptions([...students, ...instructors.data, ...drivingSchools.data].map(_recordToOption));
    setLoading(false);
  }, [inputValue]);

  useDebouncedEffect(fetchData, 150, [inputValue]);

  return { open, setOpen, value, setValue, inputValue, setInputValue, loading, options };
}

interface SearchOption {
  id: string;
  resource: string;
  name: string;
  group: string;
}

function _recordToOption(record: Student | Instructor | DrivingSchool | StudentDto): SearchOption {
  let resource: string;
  let group: string;
  let name: string = record.name as string;
  if (isStudent(record)) {
    resource = "students";
    group = "Fahrschüler";
  } else if (isInstructor(record)) {
    resource = "instructors";
    group = "Fahrlehrer";
  } else if (isServerStudent(record)) {
    resource = "students";
    group = "Fahrschüler";
    name = record.user.firstName + " " + record.user.lastName;
  } else {
    resource = "drivingSchools";
    group = "Fahrschulen";
  }

  return { id: record.id.toString(), resource, name, group };
}
