/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreditNote } from '../models/CreditNote';
import type { PaginatedCreditNoteList } from '../models/PaginatedCreditNoteList';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class CreditNoteEndpoint {

  constructor(public readonly httpRequest: BaseHttpRequest) {}

  /**
   * @returns PaginatedCreditNoteList
   * @throws ApiError
   */
  public creditNoteList({
    issuerCompanyDrivingSchoolId,
    limit,
    offset,
    ordering,
    recipientFirebaseAuthUsersId,
    recipientId,
    recipientCompanyDrivingSchoolId,
    showDisputeFees,
    state,
    type,
  }: {
    issuerCompanyDrivingSchoolId?: string,
    /**
     * Number of results to return per page.
     */
    limit?: number,
    /**
     * The initial index from which to return the results.
     */
    offset?: number,
    /**
     * Which field to use when ordering the results.
     */
    ordering?: string,
    recipientFirebaseAuthUsersId?: string,
    recipientId?: string,
    recipientCompanyDrivingSchoolId?: string,
    showDisputeFees?: boolean,
    /**
     * * `NEW` - NEW
     * * `IN_CREATION` - IN_CREATION
     * * `HAS_REFUND_TRANSACTION` - HAS_REFUND_TRANSACTION
     * * `HAS_POSITIONS` - HAS_POSITIONS
     * * `HAS_CREDIT_NOTE_NUMBER` - HAS_CREDIT_NOTE_NUMBER
     * * `PDFRENDERED` - PDFRENDERED
     * * `TRANSFER_REVERSAL_REQUIRED` - TRANSFER_REVERSAL_REQUIRED
     * * `TRANSFER_REVERSAL_CREATED` - TRANSFER_REVERSAL_CREATED
     */
    state?: 'HAS_CREDIT_NOTE_NUMBER' | 'HAS_POSITIONS' | 'HAS_REFUND_TRANSACTION' | 'IN_CREATION' | 'NEW' | 'PDFRENDERED' | 'TRANSFER_REVERSAL_CREATED' | 'TRANSFER_REVERSAL_REQUIRED',
    /**
     * * `PREPAID_CREDITS` - PREPAID_CREDITS
     * * `DRIVING_LESSON_INVOICE` - DRIVING_LESSON_INVOICE
     * * `REMOTE_LESSON` - REMOTE_LESSON
     * * `THEORY_LESSON` - THEORY_LESSON
     * * `AUTHORITY_FEE` - AUTHORITY_FEE
     * * `CREDIT_NOTE` - CREDIT_NOTE
     * * `TEACHING_MATERIAL` - TEACHING_MATERIAL
     * * `BASE_FEE` - BASE_FEE
     * * `B2B` - B2B
     * * `LEGACY_B2B` - LEGACY_B2B
     * * `LEGACY_B2C` - LEGACY_B2C
     * * `VR_EQUIPMENT_FEE` - VR_EQUIPMENT_FEE
     * * `BASE_FEE_ADDITION` - BASE_FEE_ADDITION
     * * `MISCELLANEOUS` - MISCELLANEOUS
     * * `PAYOUT_ADVANCE_RECEIPT` - PAYOUT_ADVANCE_RECEIPT
     * * `B2B_PAYOUT_ADVANCE_RECEIPT` - B2B_PAYOUT_ADVANCE_RECEIPT
     * * `ASF_COURSE_FEE` - ASF_COURSE_FEE
     * * `THEORY_PRE_EXAM` - THEORY_PRE_EXAM
     * * `APPLICATION_SERVICE` - APPLICATION_SERVICE
     */
    type?: 'APPLICATION_SERVICE' | 'ASF_COURSE_FEE' | 'AUTHORITY_FEE' | 'B2B' | 'B2B_PAYOUT_ADVANCE_RECEIPT' | 'BASE_FEE' | 'BASE_FEE_ADDITION' | 'CREDIT_NOTE' | 'DRIVING_LESSON_INVOICE' | 'LEGACY_B2B' | 'LEGACY_B2C' | 'MISCELLANEOUS' | 'PAYOUT_ADVANCE_RECEIPT' | 'PREPAID_CREDITS' | 'REMOTE_LESSON' | 'TEACHING_MATERIAL' | 'THEORY_LESSON' | 'THEORY_PRE_EXAM' | 'VR_EQUIPMENT_FEE',
  }): CancelablePromise<PaginatedCreditNoteList> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/credit-note/',
      query: {
        'issuer_company__driving_school_id': issuerCompanyDrivingSchoolId,
        'limit': limit,
        'offset': offset,
        'ordering': ordering,
        'recipient__firebase_auth_users__id': recipientFirebaseAuthUsersId,
        'recipient__id': recipientId,
        'recipient_company__driving_school_id': recipientCompanyDrivingSchoolId,
        'show_dispute_fees': showDisputeFees,
        'state': state,
        'type': type,
      },
    });
  }

  /**
   * @returns CreditNote
   * @throws ApiError
   */
  public creditNoteRetrieve({
    id,
  }: {
    /**
     * A UUID string identifying this credit note.
     */
    id: string,
  }): CancelablePromise<CreditNote> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/credit-note/{id}/',
      path: {
        'id': id,
      },
    });
  }

}
