import { useEffect } from "react";

/**
 * Side effect: Updates the document title (i.e. the title of the browser tab).
 */
export function PageTitle({ children }: { children: string }) {
  useEffect(() => {
    document.title = children === "Verteilung Fahrschüler" ? "Dashboard" : children;
  }, []);
  return <h2 style={{ margin: 0, lineHeight: 0.83, marginBottom: 30, marginTop: 10 }}>{children}</h2>;
}
