import { Avatar, Box, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {
  FunctionField,
  Link,
  ReferenceArrayField,
  ReferenceField,
  SelectField,
  Tab,
  TextField,
  useGetOne,
  useRecordContext,
} from "react-admin";
import { grants } from "../backoffice.access_control";
import { OpenInFirestoreButton } from "../buttons/OpenInFirestoreButton";
import { OpenInHubspotButton } from "../buttons/OpenInHubspotButton";
import { OpenInStripeButton } from "../buttons/OpenInStripeButton";
import { CommaSeparatedFieldList } from "../misc/CommaSeparatedFieldList";
import { StudentDrivingLessonsList } from "./student/StudentDrivingLessonsList";
import { StudentTheoryLessonsList } from "./student/StudentTheoryLessonsList";
import { autovioColors, overviewCardStyle } from "../misc/backofficeTheme";
import { Student } from "../providers/studentsProvider";
import { ChangeInstructorsLink } from "../links/ChangeInstructorsLink";
import { ChangeStatusLink } from "../links/ChangeStatusLink";
import { StudentAdminForm } from "./student/StudentAdminForm";
import { DrivingSchool } from "../providers/drivingSchoolsProvider";
import { StudentOverviewTab } from "./student/StudentOverviewTab";
import { StudentInvoicesList } from "./student/StudentInvoicesList";
import { Tabs } from "../misc/Tabs";
import { ShowBase } from "../misc/ShowBase";
import { Row } from "../misc/Row";
import { LoadingIndicator } from "../misc/LoadingIndicator";
import { MoneyField } from "../fields/MoneyField";
import EditPaymentStrategyDialogButton, { PaymentStrategyChoices } from "../buttons/EditPaymentStrategyDialogButton";
import { useEffect, useState } from "react";
import { useAutovioContext } from "../hooks/useAutovioContext";
import { StudentProfileTab } from "./student/StudentProfileTab";
import { StudentStickyNotesList } from "./student/StudentStickyNotesList";
import { StudentInAppNotificationsList } from "./student/StudentInAppNotificationsList";
import { StudentBranchField } from "../fields/StudentBranchField";
import { SetStudentBranchButton } from "../buttons/SetStudentBranchButton";
import LocalPhoneIcon from "@mui/icons-material/LocalPhoneOutlined";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { NameHeader } from "../misc/NameHeader";
import { BookedQuotesDialog } from "../dialogs/BookedQuotesDialog";

export function StudentDetails() {
  useEffect(() => {
    // For backwards compatibility ...
    if (location.pathname.endsWith("/packages")) {
      const url = new URL(location.href);
      url.pathname = url.pathname.substring(0, url.pathname.length - "/packages".length);
      url.searchParams.set("openDialog", BookedQuotesDialog.name);
      location.replace(url.toString());
    }
  }, []);
  if (location.pathname.endsWith("/packages")) {
    return null;
  }
  return (
    <ShowBase>
      <StudentDetailsTabs />
      {/** Add some space at the bottom of the page ... */}
      <Box height="20px" />
    </ShowBase>
  );
}

function StudentButtons() {
  const student = useRecordContext<Student>();
  const { greyUltraLight, green } = autovioColors;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePhoneClick = () => {
    window.location.href = `tel:${student.phoneNumber}`;
  };

  const handleEmailClick = () => {
    const url = `https://app.hubspot.com/contacts/19951845/record/0-1/${student.hubspotContactId}/?interaction=email`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Row sx={{ display: "block" }}>
      <Box sx={{ display: "flex", gap: 1 }}>
        <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handlePhoneClick}>
          <LocalPhoneIcon style={{ fontSize: 20 }} />
        </IconButton>
        <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handleEmailClick}>
          <AlternateEmailIcon sx={{ fontSize: 20 }} />
        </IconButton>
        {grants.includes("admin") && (
          <IconButton sx={{ background: greyUltraLight, color: green }} onClick={handleClick}>
            <MoreVertIcon sx={{ fontSize: 20 }} />
          </IconButton>
        )}
      </Box>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} sx={{ width: 220 }}>
        {grants.includes("viewStripe") && (
          <MenuItem onClick={handleClose}>
            <OpenInStripeButton />
          </MenuItem>
        )}

        {grants.includes("viewHubspot") && (
          <MenuItem onClick={handleClose}>
            <OpenInHubspotButton showTitle />
          </MenuItem>
        )}

        {grants.includes("viewFirestore") && (
          <MenuItem onClick={handleClose}>
            <OpenInFirestoreButton showTitle />
          </MenuItem>
        )}
      </Menu>
    </Row>
  );
}

function StudentDetailsHeader({ student }: { student: Student }) {
  function _renderPaymentMethod(student: Student) {
    const { paymentMethod } = student;
    switch (paymentMethod) {
      case "card":
        return "Kreditkarte";
      case "sepa":
        return "Lastschrift";
      default:
        return "Keine Angabe";
    }
  }

  const role = student.bookedTrainings.every((it) => it.bundleName.includes("ASF"))
    ? "ASF-Kurs-Teilnehmer"
    : "Fahrschüler";

  return (
    <Row sx={{ my: "20px" }} spacing="50px" alignItems="center">
      <Row spacing="20px" alignItems="center">
        <Avatar src={student.avatarUrl} sx={{ width: "60px", height: "60px" }} />
        <Box sx={{ ml: "20px", display: "flex", flexDirection: "column" }}>
          <NameHeader>{student.name}</NameHeader>
          <Typography sx={{ color: autovioColors.grey, fontSize: "14px", lineHeight: "1.43" }}>{role}</Typography>
        </Box>
      </Row>
      <BranchField />
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          Fahrlehrer (<ChangeInstructorsLink label="Zuweisung ändern" />)
        </Typography>
        <ReferenceArrayField label="Fahrlehrer" reference="instructors" source="instructorIds">
          <CommaSeparatedFieldList>
            <ReferenceField source="id" reference="instructors" />
          </CommaSeparatedFieldList>
        </ReferenceArrayField>
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          Status (<ChangeStatusLink label={"Status ändern"} />)
        </Typography>
        <TextField label="Status" source="verboseStatus" />
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        {grants.includes("viewDunning") && (
          <>
            <Typography color={autovioColors.grey} variant="caption">
              Saldo
            </Typography>
            <Link to={`/students/${student.id}/invoices`}>
              <MoneyField source="balance" redForNegativeValues />
            </Link>
          </>
        )}
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        <Typography color={autovioColors.grey} variant="caption">
          Zahlungsmodus
        </Typography>
        <Row spacing={1}>
          <SelectField label="Name" source="paymentStrategy" choices={PaymentStrategyChoices} />
          {grants.includes("editStudentPaymentStrategy") && <EditPaymentStrategyDialogButton />}
        </Row>
      </Stack>
      <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
        {grants.includes("viewDunning") && student.paymentStrategy !== "purchaseOnAccount" && (
          <>
            <Typography color={autovioColors.grey} variant="caption">
              Zahlungsmethode
            </Typography>
            <FunctionField render={_renderPaymentMethod} />
          </>
        )}
      </Stack>
    </Row>
  );
}

function BranchField() {
  const student = useRecordContext();
  const { data: drivingSchool } = useGetOne<DrivingSchool>("drivingSchools", { id: student.drivingSchoolId });
  if (drivingSchool && drivingSchool?.branches?.length > 1) {
    return (
      <Row>
        <Stack display="inline-flex" mb="0.2em" fontSize="0.75em">
          <Typography color={autovioColors.grey} variant="caption">
            Filiale
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <StudentBranchField drivingSchoolId={student.drivingSchoolId} />
          </Box>
        </Stack>
        <SetStudentBranchButton />
      </Row>
    );
  }

  return null;
}

function StudentDetailsTabs() {
  const student = useRecordContext<Student>();
  const [autovioContext, setAutovioContext] = useAutovioContext();
  useEffect(() => {
    if (student && student.drivingSchoolId !== autovioContext.drivingSchoolId) {
      setAutovioContext({ drivingSchoolId: student.drivingSchoolId });
    }
  }, [autovioContext.drivingSchoolId, student?.id]);

  if (!student) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Tabs
        sx={{ borderRadius: "12px", border: "1px solid #e5e5e5", padding: "5px 30px 10px 30px" }}
        header={<StudentDetailsHeader student={student} />}
        additionalButtons={<StudentButtons />}
        separateCards
      >
        <Tab label="Überblick" /* no path attribute here, so the tab is shown by default */>
          <StudentOverviewTab student={student} />
        </Tab>
        <Tab label="Stammdaten" path="profile">
          <StudentProfileTab student={student} />
        </Tab>
        <Tab label="Theorie" path="theoryLessons">
          <StudentTheoryLessonsList />
        </Tab>
        <Tab label="Fahrstunden" path="drivingLessons">
          <StudentDrivingLessonsList />
        </Tab>
        <Tab label="Konto" path="invoices">
          <StudentInvoicesList />
        </Tab>
        {grants.includes("admin") && (
          <Tab label="Admin" path="admin">
            <Box sx={{ ...overviewCardStyle, paddingBottom: "18px" /* <= 25px - 7px margin from Toolbar */ }}>
              <StudentAdminForm />
            </Box>
            <Grid2 container spacing="30px" /* see https://mui.com/material-ui/react-grid2 */>
              <Grid2 flex={1}>
                <h4 style={{ marginTop: 0 }}>Sticky Notes</h4>
                <StudentStickyNotesList />
              </Grid2>
              <Grid2 flex={1}>
                <h4 style={{ marginTop: 0 }}>In-App-Benachrichtigungen</h4>
                <StudentInAppNotificationsList />
              </Grid2>
            </Grid2>
          </Tab>
        )}
      </Tabs>
    </>
  );
}
